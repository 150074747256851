.tooltipBtn {
    color: var(--Text-Button-dark, #fff) !important;
    font-feature-settings: "clig" off, "liga" off !important;
    text-overflow: ellipsis !important;
    font-size: 16px !important;
    font-style: normal !important;
    padding: 16px !important;
    font-family: Roboto;
    font-weight: 400 !important;
    line-height: 20px !important;
    width: 200px;
    text-transform: none !important;
  }
  
  .tooltipWrapper {
    padding: 0;
    border-radius: 4px;
    background: var(--BG, #1e1c20);
  
    /* Elevation/Light/3 */
    box-shadow: 0px 47px 13px 0px rgba(0, 0, 0, 0),
      0px 30px 12px 0px rgba(0, 0, 0, 0.01), 0px 17px 10px 0px rgba(0, 0, 0, 0.05),
      0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
      0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  