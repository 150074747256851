.wrapper {
  border-radius: 80px;
  padding: 2px 2px 2px 8px;
  background: var(--Surface-Card-2, rgba(255, 255, 255, 0.3));
  width: fit-content;
  margin-top: 4px;
}

.text {
  color: var(--Text-Caption, rgba(255, 255, 255, 0.6));

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.value {
  color: var(--Text-General, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
