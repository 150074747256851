.title {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.saveBtn {
  color: var(--Text-Button-dark, #fff) !important;
  text-align: center !important;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Button */
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  border: 1px #a1b670 solid !important;
}

.labelTitle {
  color: var(--Text-Button-dark, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.labelSubTitle {
  overflow: hidden;
  color: var(--Text-Button-dark, #fff);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.hintsTitle {
  color: var(--Text-Caption, rgba(255, 255, 255, 0.6));

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.editPlotSubtitle {
  color: var(--Text-Caption, rgba(255, 255, 255, 0.6));
  /* Subtitle 2 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.formDescription {
  color: var(--Text-General, #fff);
  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
