.profileWrapper {
  width: 100%;
  background-color: #282822;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 32px;
  max-height: 100vh;
  height: 100vh;
}

.profileUsername {
  margin: 0;
  color: var(--Text-Active, #7aca14);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.profileText {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.buttonWrapper {
  padding: 10px 16px;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}

.subtitle {
  color: var(--Text-Active, #7aca14);

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.percent {
  color: var(--Text-Bright, #89a053);

  /* Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
}

.emptyElemetTitle {
  color: var(--Text-Bright, #89a053);
  text-align: center;

  /* Subtuitle */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
