.listItem {
  font-family: Roboto;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px;
  cursor: pointer;
  backdrop-filter: blur(8px);
}

.listItem:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.inputField {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
}

.inputLabel {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  color: #92918a;
  padding: 4px 16px 0px 16px;
}

.list {
  max-height: 350px;
  overflow-y: auto;
}

.tourDataToolTip {
  color: var(--Text-Button-dark, #fff);
  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}