.pointDetailWrapper {
  position: absolute;
  height: 100svh;
  background-color: #0f110e;
  z-index: 10000;
  right: 0;
  width: 368px;
  padding: 32px 64px;
}

.pointDetailHeadingTitle {
  color: var(--Text-Button-dark, #fff);

  /* Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
}

.pointDetailTitle {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pointDetailSubtitle {
  color: var(--Text-Button-dark, #FFF);
  /* Subtuitle */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pointDetailDescription {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
