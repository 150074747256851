.editProfileWrapper {
  width: 100%;
  background-color: #282822;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 32px;
  max-height: 100vh;
  height: 100vh;
}

.editProfileTitle {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.editProfileLabel {
  color: var(--Text-General, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.buttonWrapper {
  padding: 10px 16px;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}

.formDescription {
  color: var(--Text-General, #fff);
  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.avatarButton {
  position: relative !important;
  transform: translateY(-35px) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
  border-bottom-right-radius: 0% !important;
  border-top-right-radius: 0% !important;
  color: white !important;
  max-width: 28px !important;
  max-height: 28px !important;
  min-width: 28px !important;
  min-height: 28px !important;
  left: 37px !important;
  padding: 4px !important;
  cursor: pointer !important;
}

.uploadButton {
  position: relative;
  transform: translateY(88px);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  left: 50px;
  padding: 4px;
  border-radius: 1000px;
  cursor: pointer;
}

.avatarMock {
  min-width: 128px;
  width: 128px;
  height: 128px;
  background: url("../../assets/img/Avatar.svg") lightgray 50% / cover no-repeat;
  background-size: 70%;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 128px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.4);
}
