.userItemWrapper {
  width: 640px;
}

.userItemTitle {
  color: var(--Text-Active, #7aca14);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.userItemSubtitle {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.userItemParam {
  color: var(--Text-Caption, rgba(255, 255, 255, 0.6));

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.userItemParamValue {
  color: var(--Text-Bright, #89a053);

  /* Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
}
