@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.tourCardWrapper {
  padding: 16px;

  background: var(--Surface-Card, rgba(0, 0, 0, 0.6));
  backdrop-filter: blur(6px);
  width: 640px;
}

.tourContent {
  width: 304px;
  height: 304px;
}

.tourContentTitle {
  overflow: hidden;
  color: var(--Text-Button-dark, #fff);
  text-overflow: ellipsis;
  /* Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
}

.tourContentPrice {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Surface-Empty, #00be63);
  color: var(--Text-Button-dark, #fff);
  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  width: fit-content;
}

.tourParamsInfo {
  color: var(--Text-Button-dark, #fff);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.tourDescription {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  opacity: 0.7;
  margin-top: 24px;
  text-overflow: ellipsis;
  overflow: auto;
}

.tourMetaData {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}

.tourMetaDataUserAvatar {
  width: 22px;
  height: 22px;
  border-radius: var(--0, 22px);
  border: 1px solid rgba(255, 255, 255, 0);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.4);
}

.tourMetaDataUserLogin {
  color: var(--Text-Active, #7aca14);

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  margin: 0;
}

.tourMetaDataRate {
  color: var(--Text-General, #fff);
  text-align: right;

  /* Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 116.667% */
  margin: 0;
}

.tourDataToolTip {
  color: var(--Text-Button-dark, #fff);
  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.buttonWrapper {
  padding: 10px 16px;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}

.tourTagMain {
  max-width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--Surface-Pressed, #6db512);
  color: var(--Text-Button-light, #101110);

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
}

.tourFeedbackText {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: -webkit-box;
  max-width: 550px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tourFeedbackTextExpanded {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  max-width: 550px;
}

.tourFeedbackMore {
  color: #89a053;

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.tourFeedbackMoreIcon {
  max-width: 16px;
  max-height: 16px;
  color: #89a053;
}

.tourFeedbackLikeIcon {
  max-width: 24px !important;
  max-height: 24px !important;
  color: #89a053;
  cursor: pointer;
}

.tourFeedbackSlide {
  max-width: 104px;
  max-height: 104px;
  background-color: #00be63;
}
