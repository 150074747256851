.title {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pointsBlockTitle {
  color: var(--Text-Button-dark, #fff);
  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.pointsBlockSubTitle {
  color: var(--Text-Button-dark, #fff);

  /* Subtitle 2 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.buttonWrapper {
  padding: 10px 16px;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}

.titleBlock {
  min-width: 640px;
  max-width: 640px;
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 32px;
  transform: translateX(-50%);
}

.controlBlock {
  min-width: 640px;
  max-width: 640px;
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
