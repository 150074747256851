.coordinates {
  color: var(--Text-Button-dark, #fff);

  /* Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
}
