.buttonWrapper {
  padding: 10px 16px;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}

.buttonIcon {
  width: 16px;
  height: 16px;
  color: #81C728;
  margin-right: 8px;
}
