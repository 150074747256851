.mapIcon {
  align-items: center;
  background-color: #232718;
  border-radius: 50%;
  /* color: var(--Text-Active, #7aca14); */
  text-align: center;
  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
  /*   width: 60px; */
  border: 2px rgba(137, 160, 83) solid;
}

.mapIconLegend {
  align-items: center;
  background-color: #232718;
  border-radius: 50%;
  /* color: var(--Text-Active, #7aca14); */
  text-align: center;
  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
  /*   width: 60px; */
  border: 2px rgba(137, 160, 83) solid;
}

.mapIcon::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(137, 160, 83);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.mapPlotIcon {
  align-items: center;
  border-radius: 1000px;
  /* color: var(--Text-Active, #7aca14); */
  text-align: center;
  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  /* width: 45px; */
  width: 60px;
  border: 2px rgba(137, 160, 83) solid;
}

.mapPlotIcon::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(137, 160, 83);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}
