@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.wrapper {
  width: 134px;
  padding: var(--12, 12px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(8px);
}

.cardTitle {
  color: var(--Text-Button-dark, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardSubTitle {
  color: var(--Text-Button-dark, #fff);
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}
