@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.menuWrapper {
  display: flex;
  height: 100svh;
  padding: 16px;
  z-index: 1001;
}


.menuContent {
  padding: 16px;
  border-radius: 1000px;
  border-right: 1px solid rgba(122, 202, 20, 0);
  border-left: 1px solid rgba(122, 202, 20, 0);
  background: radial-gradient(
      43.93% 100% at 0% 44.09%,
      rgba(122, 202, 20, 0.14) 0%,
      rgba(122, 202, 20, 0.04) 56.91%,
      rgba(122, 202, 20, 0) 100%
    ),
    linear-gradient(
      0deg,
      var(--Surface-Card, rgba(0, 0, 0, 0.6)) 0%,
      var(--Surface-Card, rgba(0, 0, 0, 0.6)) 100%
    ),
    var(--Surface-Primary, #101110);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  flex: 1 0 0;
  align-self: stretch;
}
