@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core body defaults */
body {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textarea without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

:root {
  --swiper-pagination-color: rgba(255, 255, 255, 0.4);
  --swiper-pagination-bullet-size: 4px;
  --swiper-pagination-bullet-width: 4px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

::-webkit-scrollbar {
  width: 0;
}

#custom_scroll {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a1b670;
    border-radius: 3px;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  position: absolute;
  top: 40vh !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 8px;
  backdrop-filter: blur(10px);
}
.leaflet-popup-content-wrapper {
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0) !important;

  background: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  min-width: 180px !important;
  max-width: 180px !important;
}

.leaflet-popup-tip {
  background: rgba(255, 255, 255, 0.1) !important;
  display: none;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) grayscale(70%);
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: #a1b670 !important;
}

.leaflet-popup {
  bottom: 0 !important;
}

.leaflet-control-zoom {
  display: none;
}

.MuiTooltip-tooltip {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 4px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

h1,
h2,
h3,
h4 {
  text-wrap: pretty;
}

.maplibregl-canvas {
  filter: invert(100%) hue-rotate(180deg) grayscale(70%);
}

.maplibregl-ctrl {
  display: none !important;
}