.emptyPageTitle {
  color: var(--Text-General, #fff);
  text-align: center;

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  margin: 0;
}
