@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.tourListButton {
  border-radius: 4px;
  background: var(--Surface-Green, #a1b670);
}

.tourListWrapper {
  overflow-y: auto;
  max-height: 100svh;
  min-width: 334px;
  gap: 16px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  margin-left: 16px;
  margin-top: 16px;
  padding-bottom: 32px;
}

.toursListReviewWrapper {
  max-width: 448px;
  min-width: 448px;
  background: #000000cc;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 1000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.toursListReviewCurrentTour {
  padding: 8px;
  background-color: #89a05333;
  display: flex;
  flex-direction: row !important;
  gap: 8px;
}

.toursListReviewCurrentTourimage {
  border-radius: 4px;
  width: 32px;
  height: 32px;
}

.toursListReviewCurrentTourTitle {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
  margin: 0;
}

.toursListReviewCurrentTourSubtitle {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #89a053;
  cursor: pointer;
}

.toursListReviewCurrentTourText {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.tourFeedbackMore {
  color: #89a053;

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.tourFeedbackTooltip {
  transform: translateX(-20px);
  width: 256px;
  padding: 0;
  border-radius: 4px;
  background: var(--BG, #1e1c20);

  /* Elevation/Light/3 */
  box-shadow: 0px 47px 13px 0px rgba(0, 0, 0, 0),
    0px 30px 12px 0px rgba(0, 0, 0, 0.01), 0px 17px 10px 0px rgba(0, 0, 0, 0.05),
    0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.tourFeedbackTooltipItem {
  color: #ffffff;
  padding: 16px;
  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  cursor: pointer;
}

.tourFeedbackMoreIcon {
  max-width: 16px;
  max-height: 16px;
  color: #89a053;
}
