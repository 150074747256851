.partsTitle {
  color: var(--Text-Button-dark, #fff);
  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.partsSubtitle {
  color: var(--Text-Button-dark, #fff);

  /* Subtitle 2 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
