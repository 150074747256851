.wrapper {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0);
  
    background: var(--Surface-Card-2, rgba(255, 255, 255, 0.1));
  
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
  }
  
  .buttonWrapper {
    padding: 10px 16px;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 12px !important; /* 100% */
    letter-spacing: 0.12px !important;
    text-transform: uppercase !important;
  }
  
  .title {
    color: var(--Text-Button-dark, #fff);
  
    /* Subtuitle */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .content {
    color: var(--Text-Button-dark, #fff);
  
    /* Bold */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 116.667% */
  }
  