@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.tabberItem {
  padding: 8px;
  color: var(--Text-General, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off !important;

  /* Button */
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase !important;
}
