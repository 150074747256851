@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.cardWrapper {
  width: 304px;
  padding: var(--16, 16px);
  height: 100%;
  margin: 32px 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0);
}

.cardTextBlock {
  min-height: 236px;
}

.cardTitle {
  overflow: hidden;
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardText {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  max-height: 182px;
  min-height: 182px;
  overflow-y: scroll;
}

.cardDescriptionBlock {
  width: 100%;
  padding-top: 3px;
  border-top: 1px rgba(255, 255, 255, 0.5) solid;
}

.cardDescriptionText {
  color: var(--Text-Button-dark, #fff);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
}

.tourMetaDataUserAvatar {
  width: 22px;
  height: 22px;
  border-radius: var(--0, 22px);
  border: 1px solid rgba(255, 255, 255, 0);
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.4);
}

.tourMetaDataUserLogin {
  color: var(--Text-Active, #7aca14);

  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 100% */
  margin: 0;
}

.tourMetaDataRate {
  color: var(--Text-General, #fff);
  text-align: right;

  /* Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 116.667% */
  margin: 0;
}
