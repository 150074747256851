.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  /*     background: #1B2FB2; */
  background-color: #ccc;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 12px 0px 5px 0px;
  cursor: pointer;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 5px #89a053 solid;
  background-size: 33%;
}

.audioPlayerTime {
  color: var(--Text-Button-dark, #fff);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  opacity: 0.3;
  margin-top: 16px;
}

.audioPlayerName {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
