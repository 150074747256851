.subscribersWrapper {
  width: 100%;
  background-color: #282822;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 32px;
  max-height: 100vh !important;
  height: 100vh !important;
}

.subscribersTitle {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
