.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0);

  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(8px);
  align-items: center;
  padding: 12px 12px 12px 8px;
  overflow: hidden;
}

.title {
  color: var(--Text-Button-dark, #fff);

  /* H2 */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitleBlock {

}

.subtitle {
  color: var(--Text-Button-dark, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  display: -webkit-box;
  max-width: calc(100vw - 800px);
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
