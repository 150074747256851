@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.wrapper {
  width: 100vw;
  height: 100vh;
  background: var(--BG, #1e1c20);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.title {
  color: var(--Text-Button-dark, #fff);
  text-align: center;

  /* Subtuitle */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.labelTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.labelSubTitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.fadeInTop {
  animation: fadeInTop 0.25s ease-in;
}

@keyframes fadeInTop {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in;
}

.userPolicyText {
  position: absolute;
  bottom: 26px;
  color: var(--Text-Button-dark, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 366px;
}

.loaderText {
  color: var(--Text-Button-dark, #fff);
  text-align: center;

  /* Subtuitle */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  max-width: 366px;
  text-wrap: wrap;
  line-height: normal;
  margin-top: 28px;
  margin-bottom: 24px;
}

.loaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loaderButtonText {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 12px !important; /* 100% */
  letter-spacing: 0.12px !important;
  text-transform: uppercase;
  width: 366px;
  border-radius: 4px !important;
  border: 1px solid var(--Outline-Button, #89a053) !important;
  color: white !important;
  padding: 10px !important;
}
