.toursMapFiltersWrapper {
  width: 396px;
  border-radius: var(--4, 4px);
  background: var(--Surface-Card, rgba(0, 0, 0, 0.6));
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  padding: var(--16, 16px);
  animation: fadeIn 1s ease-in;
}

.toursMapFiltersTitle {
  overflow: hidden;
  color: var(--Text-Button-dark, #fff);
  text-overflow: ellipsis;

  /* Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
}
.editProfileLabel {
  color: var(--Text-General, #fff);

  /* Text */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
.filtersSubtitle {
  color: var(--Text-Button-dark, #fff);

  /* Subtitle 2 */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filtersMark {
  color: var(--Text-Caption, rgba(255, 255, 255, 0.6));
  /* Tag */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 10px; /* 100% */
}

@keyframes fadeInTop {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
